import { MoneyCollectFilled } from "@ant-design/icons";
import { Flex, Text, useMediaQuery } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { FaCoins } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";

export default function Telemetria() {
  const [totalFornecedores, setTotalFornecedores] = useState([]);
  const [todosPagamentos, setTodosPagamentos] = useState([]);
  const [pagamentos, setPagamentos] = useState({});
  const [machines, setMachines] = useState([]);
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

  const { setDataUser, loading, authInfo, setNotiMessage } =
    useContext(AuthContext);
  const { dataUser } = authInfo;
  let navigate = useNavigate();
  const token = authInfo?.dataUser?.token;

  const dataData = () => {
    axios
      .get(`${process.env.REACT_APP_SERVIDOR}/maquinas`, {
        headers: {
          "x-access-token": token,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setTotalFornecedores(res.data);
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        if ([401, 403].includes(err.response.status)) {
          // setNotiMessage('A sua sessão expirou, para continuar faça login novamente.');
          setNotiMessage({
            type: "error",
            message:
              "A sua sessão expirou, para continuar faça login novamente.",
          });
          setDataUser(null);
        }
      });
  };

  useEffect(() => {
    dataData();
  }, []);

  useEffect(() => {
    const getRows = () => {
      const pagamentos = [];
      machines.forEach(({ nome, descricao, payments }) => {
        payments.pagamentos.forEach((pagamento) => {
          pagamentos.push({ cliente: nome, descricao, ...pagamento });
        });
      });

      setTodosPagamentos(pagamentos);
    };

    getRows();
  }, [machines]);

  useEffect(() => {
    const fetchMaquinas = async (maquinas) => {
      const promises = [];

      maquinas.forEach((maquina) => {
        const promise = axios
          .get(`${process.env.REACT_APP_SERVIDOR}/pagamentos/${maquina.id}`, {
            headers: {
              "x-access-token": token,
              "content-type": "application/json",
            },
          })
          .then((response) => {
            return {
              nome: maquina.nome,
              descricao: maquina.descricao,
              payments: response.data,
            };
          })
          .catch((error) => {
            console.error(
              `Erro ao buscar a máquina ${maquina.nome} do cliente ${maquina.nome}:`,
              error
            );
            return null; // Retorna null em caso de erro
          });

        promises.push(promise);
      });

      const resultados = await Promise.all(promises);

      return resultados;
    };

    fetchMaquinas(totalFornecedores).then((resultados) => {
      setMachines(resultados);
    });
  }, [token, totalFornecedores]);

  useEffect(() => {
    const calcularPagamentos = () => {
      const pagamentosCredito = calcularTotalPorTipo("credit_card");
      const pagamentosDebito = calcularTotalPorTipo("debit_card");
      const pagamentosPix = calcularTotalPorTipo("bank_transfer");
      const pagamentosEspecie = calcularTotalPorTipo("CASH");

      setPagamentos((prev) => ({
        ...prev,
        credito: pagamentosCredito,
        debito: pagamentosDebito,
        pix: pagamentosPix,
        especie: pagamentosEspecie,
      }));
    };

    const pagamentosNormal = todosPagamentos.filter(
      ({ estornado }) => !estornado
    );

    const calcularTotalPorTipo = (tipo) => {
      return pagamentosNormal
        .filter((item) => item.tipo === tipo)
        .reduce((acc, curr) => acc + Number(curr.valor), 0);
    };

    calcularPagamentos();
  }, [totalFornecedores, pagamentos, todosPagamentos]);

  return (
    <Flex flexDirection="column" minH="100vh">
      <Text color="white" fontSize="24px">
        Telemetria
      </Text>
      <Flex width="100%">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: isLargerThan800
              ? "repeat(4,1fr)"
              : "repeat(1,1fr)",
            width: "100%",
            gridGap: "20px",
          }}
        >
          <Flex
            padding="10px 20px 0px 20px"
            borderRadius="20px"
            backgroundColor="#1F2630"
            flexDirection="column"
          >
            <Flex width="100%" alignItems="center" gap="10px">
              <FaCoins style={{ marginTop: "-20px", color: "white" }} />
              <Text color="white" fontSize="18px">
                Dinheiro em Espécie
              </Text>
            </Flex>
            <Text
              color="white"
              fontSize="24px"
              marginTop="-30px"
              fontWeight="700"
            >
              {Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(pagamentos?.especie)}
            </Text>
          </Flex>
          <Flex
            padding="10px 20px 0px 20px"
            borderRadius="20px"
            backgroundColor="#1F2630"
            flexDirection="column"
          >
            <Flex width="100%" alignItems="center" gap="10px">
              <FaCoins style={{ marginTop: "-20px", color: "white" }} />
              <Text color="white" fontSize="18px">
                PIX
              </Text>
            </Flex>
            <Text
              color="white"
              fontSize="24px"
              marginTop="-30px"
              fontWeight="700"
            >
              {Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(pagamentos?.pix)}
            </Text>
          </Flex>
          <Flex
            padding="10px 20px 0px 20px"
            borderRadius="20px"
            backgroundColor="#1F2630"
            flexDirection="column"
          >
            <Flex width="100%" alignItems="center" gap="10px">
              <FaCoins style={{ marginTop: "-20px", color: "white" }} />
              <Text color="white" fontSize="18px">
                Débito
              </Text>
            </Flex>
            <Text
              color="white"
              fontSize="24px"
              marginTop="-30px"
              fontWeight="700"
            >
              {Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(pagamentos?.debito)}
            </Text>
          </Flex>
          <Flex
            padding="10px 20px 0px 20px"
            borderRadius="20px"
            backgroundColor="#1F2630"
            flexDirection="column"
          >
            <Flex width="100%" alignItems="center" gap="10px">
              <FaCoins style={{ marginTop: "-20px", color: "white" }} />
              <Text color="white" fontSize="18px">
                Crédito
              </Text>
            </Flex>
            <Text
              color="white"
              fontSize="24px"
              marginTop="-30px"
              fontWeight="700"
            >
              {Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(pagamentos?.credito)}
            </Text>
          </Flex>
          <Flex
            padding="10px 20px 0px 20px"
            borderRadius="20px"
            backgroundColor="#1F2630"
            flexDirection="column"
          >
            <Flex width="100%" alignItems="center" gap="10px">
              <FaCoins style={{ marginTop: "-20px", color: "white" }} />
              <Text color="white" fontSize="18px">
                Produtos entregues
              </Text>
            </Flex>
            <Text
              color="white"
              fontSize="24px"
              marginTop="-30px"
              fontWeight="700"
            >
              22
            </Text>
          </Flex>
          <Flex
            padding="10px 20px 0px 20px"
            borderRadius="20px"
            backgroundColor="#1F2630"
            flexDirection="column"
          >
            <Flex width="100%" alignItems="center" gap="10px">
              <FaCoins style={{ marginTop: "-20px", color: "white" }} />
              <Text color="white" fontSize="18px">
                Valor total
              </Text>
            </Flex>
            <Text
              color="white"
              fontSize="24px"
              marginTop="-30px"
              fontWeight="700"
            >
              {Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(
                pagamentos?.pix +
                  pagamentos?.debito +
                  pagamentos?.credito +
                  pagamentos?.especie
              )}
            </Text>
          </Flex>
        </div>
      </Flex>
    </Flex>
  );
}
