import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import { AuthContext } from "../../../contexts/AuthContext";
import { useContext, useEffect, useState } from "react";
import {
  FaBars,
  FaBitcoin,
  FaCoins,
  FaEdit,
  FaLock,
  FaMoneyBill,
  FaMoneyCheck,
  FaTrash,
  FaTrashAlt,
  FaWifi,
} from "react-icons/fa";
import { MoneyCollectFilled } from "@ant-design/icons";

const ModalModuleEdit = ({ isOpen, onClose, moduleProps = {} }) => {
  const [moduleInfo, setModuleInfo] = useState(moduleProps);

  const { authInfo, setNotiMessage } = useContext(AuthContext);

  const token = authInfo?.dataUser?.token;

  const onSave = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_SERVIDOR}/maquina-cliente`,
        {
          id: moduleInfo.id,
          nome: moduleInfo.nome,
          descricao: moduleInfo.descricao,
          estoque: Number(moduleInfo.estoque),
          store_id: String(moduleInfo.store_id),
        },
        {
          headers: {
            "x-access-token": token,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        onClose();
        window.location.reload();
      });
  };

  useEffect(() => {
    setModuleInfo(moduleProps);
  }, [moduleProps]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent backgroundColor="#1a202c">
        <ModalCloseButton color="white" />
        <ModalBody style={{ marginTop: "50px" }}>
          <form>
            <FormControl>
              <FormLabel color="white">Nome</FormLabel>
              <Input
                type="text"
                value={moduleInfo.nome}
                color="white"
                onChange={({ target }) =>
                  setModuleInfo((prev) => ({
                    ...prev,
                    nome: target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl marginTop="20px">
              <FormLabel color="white">Loja</FormLabel>
              <Input
                type="text"
                value={moduleInfo.descricao}
                color="white"
                onChange={({ target }) =>
                  setModuleInfo((prev) => ({
                    ...prev,
                    descricao: target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl marginTop="20px">
              <FormLabel color="white">Estoque</FormLabel>
              <Input
                type="text"
                value={moduleInfo.estoque}
                color="white"
                onChange={({ target }) =>
                  setModuleInfo((prev) => ({
                    ...prev,
                    estoque: target.value,
                  }))
                }
              />
            </FormControl>
            <FormControl marginTop="20px">
              <FormLabel color="white">Store ID</FormLabel>
              <Input
                type="text"
                value={moduleInfo.store_id}
                color="white"
                onChange={({ target }) =>
                  setModuleInfo((prev) => ({
                    ...prev,
                    store_id: target.value,
                  }))
                }
              />
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose} color="white">
            Cancelar
          </Button>
          <Button colorScheme="blue" mr={3} onClick={onSave}>
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const DeleteModal = ({ isOpen, onClose, id }) => {
  const { setDataUser, loading, authInfo, setNotiMessage } =
    useContext(AuthContext);
  const token = authInfo?.dataUser?.token;

  const deleteHandler = () => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_SERVIDOR}/maquina-cliente`,
      headers: {
        "x-access-token": token,
        "Content-Type": "application/json",
      },
      data: { id },
    };

    axios
      .request(config)
      .then((res) => {
        setNotiMessage({
          type: "success",
          message: "Deleted Successfully.",
        });
        onClose();
        window.location.reload();
      })
      .catch((err) => {
        if ([401, 403].includes(err.response.status)) {
          setNotiMessage({
            type: "error",
            message: "Delete Failed",
          });
          setDataUser(null);
        }
      });
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent backgroundColor="#1a202c">
        <ModalCloseButton color="white" />
        <ModalBody style={{ marginTop: "50px", textAlign: "center" }}>
          <Text fontSize="20px" color="white">
            Deseja excluir essa máquina?
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose} color="white">
            Cancelar
          </Button>
          <Button colorScheme="blue" mr={3} onClick={deleteHandler}>
            Excluir
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const RemoteCreditModal = ({ isOpen, onClose, id }) => {
  const { setDataUser, loading, authInfo, setNotiMessage } =
    useContext(AuthContext);
  const token = authInfo?.dataUser?.token;

  const [value, setValue] = useState(0);

  const addCredit = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVIDOR}/credito-remoto-cliente`,
        {
          id: id,
          valor: value,
        },
        {
          headers: {
            "x-access-token": token,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setNotiMessage({
          type: "success",
          message: `${res?.data?.retorno}`,
        });
        onClose(); // Fecha o modal após o sucesso
      })
      .catch((err) => {
        if ([401, 403].includes(err.response.status)) {
          setNotiMessage({
            type: "error",
            message:
              "A sua sessão expirou, para continuar faça login novamente.",
          });
          setDataUser(null);
        } else {
          setNotiMessage({
            type: "error",
            message: `Erro, algo deu errado ${err.response?.data?.msg}`,
          });
        }
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent backgroundColor="#1a202c">
        <ModalCloseButton color="white" />
        <ModalBody style={{ marginTop: "50px", textAlign: "center" }}>
          <form>
            <FormControl>
              <FormLabel color="white">Valor</FormLabel>
              <Input
                type="number"
                value={value}
                color="white"
                onChange={({ target }) => setValue(target.value)}
              />
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose} color="white">
            Cancelar
          </Button>
          <Button colorScheme="blue" mr={3} onClick={addCredit}>
            Adicionar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default function Modules() {
  const [modules, setModules] = useState([]);
  const [moduleProps, setModuleProps] = useState({});
  const [selectedId, setSelectedId] = useState(undefined);
  const { setDataUser, loading, authInfo, setNotiMessage } =
    useContext(AuthContext);
  const token = authInfo?.dataUser?.token;

  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    onOpen: onOpenDeleteModal,
    onClose: onCloseDeleteModal,
    isOpen: isOpenDeleteModal,
  } = useDisclosure();
  const {
    onOpen: onOpenRemoteCreditModal,
    onClose: onCloseRemoteCreditModal,
    isOpen: isOpenRemoteCreditModal,
  } = useDisclosure();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVIDOR}/maquinas`, {
        headers: {
          "x-access-token": token,
          "content-type": "application/json",
        },
      })
      .then((response) => {
        setModules(response.data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const availableModules = modules.filter(({ status }) => status !== "OFFLINE");
  const unavailableModules = modules.filter(
    ({ status }) => status === "OFFLINE"
  );

  return (
    <Flex minH="90vh" flexDirection="column">
      <Text color="white" fontSize="24px">
        Módulos
      </Text>
      <Tabs>
        <TabList borderColor="#1F2630">
          <Tab
            color="white"
            _selected={{ borderColor: "white" }}
            fontSize="14px"
          >
            Todos{" "}
            <span
              style={{
                marginLeft: "10px",
                padding: "5px 10px",
                backgroundColor: "#01B0D1",
                borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              {modules.length}
            </span>
          </Tab>
          <Tab
            color="white"
            _selected={{ borderColor: "white" }}
            fontSize="14px"
          >
            Online{" "}
            <span
              style={{
                marginLeft: "10px",
                padding: "5px 10px",
                backgroundColor: "#21BD52",
                borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              {availableModules.length}
            </span>
          </Tab>
          <Tab
            color="white"
            _selected={{ borderColor: "white" }}
            fontSize="14px"
          >
            Offline
            <span
              style={{
                marginLeft: "10px",
                padding: "5px 10px",
                backgroundColor: "#F45031",
                borderRadius: "8px",
                fontSize: "12px",
              }}
            >
              {unavailableModules.length}
            </span>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <TableContainer minH="500px">
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th borderColor="#1F2630"></Th>
                    <Th borderColor="#1F2630">Nome</Th>
                    <Th borderColor="#1F2630">Conexão</Th>
                    <Th borderColor="#1F2630">Loja</Th>
                    <Th borderColor="#1F2630">Estoque</Th>
                    <Th borderColor="#1F2630">Último Pagamento</Th>
                    <Th borderColor="#1F2630">Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {modules.map(
                    ({
                      id,
                      nome,
                      descricao,
                      estoque,
                      ultimoPagamentoRecebido,
                      status,
                      store_id,
                    }) => (
                      <Tr>
                        <Td borderColor="#1F2630">
                          <Popover placement="bottom-end">
                            <PopoverTrigger>
                              <Button
                                backgroundColor="transparent"
                                _active={{ backgroundColor: "transparent" }}
                                _hover={{ backgroundColor: "transparent" }}
                              >
                                <FaBars color="white" />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent maxW="180px">
                              <PopoverArrow />
                              <PopoverBody>
                                <Flex
                                  gap="10px"
                                  onClick={() => {
                                    setModuleProps({
                                      id,
                                      nome,
                                      descricao,
                                      estoque,
                                      store_id,
                                    });
                                    onOpen();
                                  }}
                                >
                                  <FaEdit />
                                  Editar
                                </Flex>
                                <Flex
                                  gap="10px"
                                  marginTop="10px"
                                  onClick={() => {
                                    setSelectedId(id);
                                    onOpenRemoteCreditModal();
                                  }}
                                >
                                  <FaBitcoin />
                                  Crédito Remoto
                                </Flex>
                                <Flex
                                  gap="10px"
                                  marginTop="10px"
                                  color="red"
                                  onClick={() => {
                                    setSelectedId(id);
                                    onOpenDeleteModal();
                                  }}
                                >
                                  <FaTrash />
                                  Excluir
                                </Flex>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {nome}
                        </Td>
                        <Td borderColor="#1F2630">
                          <FaWifi
                            color={status === "OFFLINE" ? "red" : "green"}
                          />
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {descricao}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {estoque}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {ultimoPagamentoRecebido}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {status}
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel>
            <TableContainer minH="500px">
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th borderColor="#1F2630"></Th>
                    <Th borderColor="#1F2630">Nome</Th>
                    <Th borderColor="#1F2630">Conexão</Th>
                    <Th borderColor="#1F2630">Loja</Th>
                    <Th borderColor="#1F2630">Estoque</Th>
                    <Th borderColor="#1F2630">Último Pagamento</Th>
                    <Th borderColor="#1F2630">Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {availableModules.map(
                    ({
                      id,
                      nome,
                      descricao,
                      estoque,
                      ultimoPagamentoRecebido,
                      status,
                      store_id,
                    }) => (
                      <Tr>
                        <Td borderColor="#1F2630">
                          <Popover placement="bottom-end">
                            <PopoverTrigger>
                              <Button
                                backgroundColor="transparent"
                                _active={{ backgroundColor: "transparent" }}
                                _hover={{ backgroundColor: "transparent" }}
                              >
                                <FaBars color="white" />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent maxW="180px">
                              <PopoverArrow />
                              <PopoverBody>
                                <Flex
                                  gap="10px"
                                  onClick={() => {
                                    setModuleProps({
                                      id,
                                      nome,
                                      descricao,
                                      estoque,
                                      store_id,
                                    });
                                    onOpen();
                                  }}
                                >
                                  <FaEdit />
                                  Editar
                                </Flex>
                                <Flex
                                  gap="10px"
                                  marginTop="10px"
                                  onClick={() => {
                                    setSelectedId(id);
                                    onOpenRemoteCreditModal();
                                  }}
                                >
                                  <FaBitcoin />
                                  Crédito Remoto
                                </Flex>
                                <Flex
                                  gap="10px"
                                  marginTop="10px"
                                  color="red"
                                  onClick={() => {
                                    setSelectedId(id);
                                    onOpenDeleteModal();
                                  }}
                                >
                                  <FaTrash />
                                  Excluir
                                </Flex>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {nome}
                        </Td>
                        <Td borderColor="#1F2630">
                          <FaWifi
                            color={status === "OFFLINE" ? "red" : "green"}
                          />
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {descricao}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {estoque}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {ultimoPagamentoRecebido}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {status}
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel>
            <TableContainer minH="500px">
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th borderColor="#1F2630"></Th>
                    <Th borderColor="#1F2630">Nome</Th>
                    <Th borderColor="#1F2630">Conexão</Th>
                    <Th borderColor="#1F2630">Loja</Th>
                    <Th borderColor="#1F2630">Estoque</Th>
                    <Th borderColor="#1F2630">Último Pagamento</Th>
                    <Th borderColor="#1F2630">Status</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {unavailableModules.map(
                    ({
                      id,
                      nome,
                      descricao,
                      estoque,
                      ultimoPagamentoRecebido,
                      status,
                      store_id,
                    }) => (
                      <Tr>
                        <Td borderColor="#1F2630">
                          <Popover placement="bottom-end">
                            <PopoverTrigger>
                              <Button
                                backgroundColor="transparent"
                                _active={{ backgroundColor: "transparent" }}
                                _hover={{ backgroundColor: "transparent" }}
                              >
                                <FaBars color="white" />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent maxW="180px">
                              <PopoverArrow />
                              <PopoverBody>
                                <Flex
                                  gap="10px"
                                  onClick={() => {
                                    setModuleProps({
                                      id,
                                      nome,
                                      descricao,
                                      estoque,
                                      store_id,
                                    });
                                    onOpen();
                                  }}
                                >
                                  <FaEdit />
                                  Editar
                                </Flex>
                                <Flex
                                  gap="10px"
                                  marginTop="10px"
                                  onClick={() => {
                                    setSelectedId(id);
                                    onOpenRemoteCreditModal();
                                  }}
                                >
                                  <FaBitcoin />
                                  Crédito Remoto
                                </Flex>
                                <Flex
                                  gap="10px"
                                  marginTop="10px"
                                  color="red"
                                  onClick={() => {
                                    setSelectedId(id);
                                    onOpenDeleteModal();
                                  }}
                                >
                                  <FaTrash />
                                  Excluir
                                </Flex>
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {nome}
                        </Td>
                        <Td borderColor="#1F2630">
                          <FaWifi
                            color={status === "OFFLINE" ? "red" : "green"}
                          />
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {descricao}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {estoque}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {ultimoPagamentoRecebido}
                        </Td>
                        <Td color="white" borderColor="#1F2630">
                          {status}
                        </Td>
                      </Tr>
                    )
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <ModalModuleEdit
        isOpen={isOpen}
        onClose={onClose}
        moduleProps={moduleProps}
      />
      <DeleteModal
        isOpen={isOpenDeleteModal}
        onClose={onCloseDeleteModal}
        id={selectedId}
      />
      <RemoteCreditModal
        isOpen={isOpenRemoteCreditModal}
        onClose={onCloseRemoteCreditModal}
        id={selectedId}
      />
    </Flex>
  );
}
