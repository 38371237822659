import React, { useContext, useEffect, useState } from "react";
import "./Dashboard.css";
import axios from "axios";
import * as links from "../../../utils/links";
import { AuthContext } from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import LoadingAction from "../../../themes/LoadingAction/LoadingAction";
import { Flex, Grid, Text, useMediaQuery } from "@chakra-ui/react";
import { FaSignal } from "react-icons/fa";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Col, Row } from "antd";

const CardWithLineChart = ({ title, categories, series }) => {
  const options = {
    chart: {
      type: "line",
      backgroundColor: "transparent",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories,
      labels: {
        style: {
          color: "#ffffff",
        },
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        style: {
          color: "#ffffff",
        },
      },
    },
    series,
    legend: {
      itemStyle: {
        color: "#ffffff",
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
          style: {
            color: "#ffffff",
          },
        },
      },
    },
  };

  return (
    <Flex
      width="100%"
      gap="20px"
      padding="10px 20px"
      borderRadius="20px"
      backgroundColor="#1F2630"
      flexDirection="column"
      minH="350px"
    >
      <Text color="white" fontSize="18px">
        {title}
      </Text>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Flex>
  );
};

const InformationCard = ({ title, value, signalColor }) => {
  return (
    <Flex
      width="100%"
      gap="20px"
      padding="10px 20px"
      borderRadius="20px"
      backgroundColor="#1F2630"
    >
      <Flex flexDirection="column" flex={1} justifyContent="space-between">
        <Text color="white" fontSize="18px">
          {title}
        </Text>
        <Text color="white" fontSize="22px" fontWeight="700">
          {value}
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="flex-end" flex={1}>
        <FaSignal fontSize="36px" color={signalColor} />
      </Flex>
    </Flex>
  );
};

const FilterBox = ({ text, isActive, onClick }) => {
  return (
    <Flex
      padding="10px 20px"
      justifyContent="center"
      alignItems="center"
      borderRadius="8px"
      backgroundColor="#212530"
      color="white"
      onClick={onClick}
      border={isActive && "1px solid white"}
      cursor="pointer"
    >
      {text}
    </Flex>
  );
};

const DashboardFornecedor = (props) => {
  const { setDataUser, loading, authInfo, setNotiMessage } =
    useContext(AuthContext);
  const { dataUser } = authInfo;
  let navigate = useNavigate();
  const token = authInfo?.dataUser?.token;
  const premiumExpiration = authInfo?.dataUser?.premiumExpiration ?? null;
  const hasData = !!authInfo?.dataUser?.hasData;
  const [favorites, setFavorites] = useState([]);
  const [meusFits, setMeusFits] = useState(null);
  const [totalCanais, setTotalCanais] = useState(null);
  const [totalFornecedores, setTotalFornecedores] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataCurrentDetail, setDataCurrentDetail] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("today");
  const [machines, setMachines] = useState([]);
  const [todosPagamentos, setTodosPagamentos] = useState([]);

  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

  // useEffect(() => {
  //     dataData();
  // }, [])

  useEffect(() => {
    dataData();

    const intervalId = setInterval(() => {
      dataData();
    }, 60000);

    // Limpar o intervalo quando o componente for desmontado para evitar vazamento de memória
    return () => clearInterval(intervalId);
  }, []);

  const dataData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVIDOR}/maquinas`, {
        headers: {
          "x-access-token": token,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          setTotalFornecedores(res.data);
        } else {
          throw new Error();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if ([401, 403].includes(err.response.status)) {
          // setNotiMessage('A sua sessão expirou, para continuar faça login novamente.');
          setNotiMessage({
            type: "error",
            message:
              "A sua sessão expirou, para continuar faça login novamente.",
          });
          setDataUser(null);
        }
      });
  };

  useEffect(() => {
    const getRows = () => {
      const pagamentos = [];
      machines.forEach(({ nome, descricao, payments }) => {
        payments.pagamentos.forEach((pagamento) => {
          pagamentos.push({ cliente: nome, descricao, ...pagamento });
        });
      });

      setTodosPagamentos(pagamentos);
    };

    getRows();
  }, [machines]);

  useEffect(() => {
    const fetchMaquinas = async (maquinas) => {
      const promises = [];

      maquinas.forEach((maquina) => {
        const promise = axios
          .get(`${process.env.REACT_APP_SERVIDOR}/pagamentos/${maquina.id}`, {
            headers: {
              "x-access-token": token,
              "content-type": "application/json",
            },
          })
          .then((response) => {
            return {
              nome: maquina.nome,
              descricao: maquina.descricao,
              status: maquina.status,
              payments: response.data,
            };
          })
          .catch((error) => {
            console.error(
              `Erro ao buscar a máquina ${maquina.nome} do cliente ${maquina.nome}:`,
              error
            );
            return null; // Retorna null em caso de erro
          });

        promises.push(promise);
      });

      const resultados = await Promise.all(promises);

      return resultados;
    };

    fetchMaquinas(totalFornecedores).then((resultados) => {
      setMachines(resultados);
    });
  }, [token, totalFornecedores]);

  const handleMaquinaClick = (id, nome, storeId, pulso, estoque, descricao) => {
    const maquinaInfos = { nome, storeId, pulso, estoque, descricao };
    navigate(`${links.FORNECEDOR_SEARCH_CANAIS}/${id}`, {
      state: maquinaInfos,
    });
  };

  const availableModules = totalFornecedores.filter(
    ({ status }) => status !== "OFFLINE"
  );

  const valorTotalVendas = todosPagamentos
    .filter(({ estornado }) => !estornado)
    .reduce((acc, curr) => {
      return acc + Number(curr.valor);
    }, 0);

  return (
    <Flex width="100%" flexDirection="column" gap="20px">
      {isLoading && <LoadingAction />}
      {/* <div className="WarningMsg">
                    {dataUser.warningMsg}
                </div> */}
      <Flex width="100%" gap="10px">
        <FilterBox
          text="Hoje"
          isActive={selectedFilter === "today"}
          onClick={() => setSelectedFilter("today")}
        />
        <FilterBox
          text="7 dias"
          isActive={selectedFilter === "weekly"}
          onClick={() => setSelectedFilter("weekly")}
        />
        <FilterBox
          text="30 dias"
          isActive={selectedFilter === "month"}
          onClick={() => setSelectedFilter("month")}
        />
        <FilterBox
          text="Todos"
          isActive={selectedFilter === "all"}
          onClick={() => setSelectedFilter("all")}
        />
      </Flex>
      <Grid
        gridTemplateColumns={
          isLargerThan800 ? "repeat(3, 1fr)" : "repeat(1, 1fr)"
        }
        gridGap="20px"
      >
        <InformationCard
          title="Total de vendas"
          value={Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(valorTotalVendas)}
          signalColor="#48B786"
        />
        <InformationCard
          title="Número de vendas"
          value={todosPagamentos.filter(({ estornado }) => !estornado).length}
          signalColor="#49CBD8"
        />
        <InformationCard
          title="Módulos online"
          value={availableModules.length}
          signalColor="#E3BA67"
        />
      </Grid>
      <CardWithLineChart
        title="Vendas dia a dia"
        categories={[
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ]}
        series={[
          {
            name: "Valor vendido",
            data: [1, 3, 2, 4, 5, 9, 1, 3, 2, 4, 5, 9],
            marker: {
              enabled: false,
            },
            linecap: "round",
          },
          {
            name: "Número de transações",
            data: [1, 3, 1, 9, 5, 11, 2, 3, 8, 4, 5, 9],
            marker: {
              enabled: false,
            },
            linecap: "round",
          },
        ]}
      />
      <CardWithLineChart
        title="Vendas mês a mês"
        categories={[
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ]}
        series={[
          {
            name: "Valor vendido",
            data: [1, 3, 2, 4, 5, 9, 1, 3, 2, 4, 5, 9],
            marker: {
              enabled: false,
            },
            linecap: "round",
          },
          {
            name: "Número de transações",
            data: [1, 3, 1, 9, 5, 11, 2, 3, 8, 4, 5, 9],
            marker: {
              enabled: false,
            },
            linecap: "round",
          },
        ]}
      />
      <Row>
        {totalFornecedores.map((post) => (
          <Col xs={24} md={24} lg={8} xl={8} className="Dashboard_col">
            <div
              className="maquina"
              key={post.id}
              onClick={() =>
                handleMaquinaClick(
                  post.id,
                  post.nome,
                  post.store_id,
                  post.pulso,
                  post.estoque,
                  post.descricao
                )
              }
            >
              <div className="maquina-info">
                <h2>{post.nome}</h2>
                <h4 style={{ fontWeight: "300" }}>
                  {post.status} - {post.descricao}
                </h4>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Flex>
  );
};

export default DashboardFornecedor;
